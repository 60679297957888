.list-group {
    left: 30px;
    &-item {
        // user-select: none;
        // margin: 1rem 0;
        // padding: 0;
        // border: none;
        // text-decoration: none;
        // padding: 1rem 0;
        // position: relative;
        // text-transform: uppercase;
        // position: relative;
        // display: inline-block;
        // color: $c-highlight;
        // letter-spacing: 0.6px;

        // &:before,
        // &:after {
        //     content: "";
        //     position: absolute;
        //     height: 0.2rem;
        //     background-color: $c-highlight;
        //     top: 50%;
        //     left: calc(100% + 2rem);
        //     transform: translate(0, -50%);
        //     transition: $t-medium $ease-default;
        //     will-change: width;
        // } 
        
        // &:before {
        //     width: 2rem;
        //     opacity: 0.2;
        // }
        
        // &:after {
        //     width: 0;
        //     opacity: 0.8;
        // }

        &.active{
            background-color: transparent;
            color: $c-highlight;

            // &:before {
            //     width: 4rem;
            // }

            // &:after {
            //     width: 4rem;
            // }
        }

        // &:hover {
        //     background-color: transparent;

        //     &:before {
        //         width: 4rem;
        //     }
        // }
    }
}