.fullpage-slider {
    // max-height: calc(100vh - 77px);
    margin-top: 7px;
    top: auto;
    bottom: 0;

    @include media-breakpoint-up (lg) {
        opacity: 0;
        height: auto;
        max-height: auto;
        margin-top: auto;
    }

    figure {
        margin: 0;
    }

    figcaption {
        display: none;
    }

    &__slide {
        height: calc(100vh - 72px);
        display: flex;
        position: relative;
        overflow: hidden;
        flex-direction: column;

        @include media-breakpoint-up (lg) {
            height: 100vh;
            flex-direction: row;
        }
    }

    &__hl,
    &__img {
        flex-basis: 50%;
        will-change: transform;
    }

    &__hl {
        color: $c-light;
        text-transform: uppercase;
        font-weight: $fw-light;
        line-height: 1.05;
        box-sizing: border-box;
        hyphens: auto;
        // font-size: $fs-xl-large;
        font-size: clamp(2rem, 20vw, 4rem);
        padding: 1rem 1.5rem 0 1.5rem;
        margin-bottom: 0;

        @include media-breakpoint-up (lg) {
            padding: 1rem 2rem 0 2rem;
            font-size: clamp(2rem, 20vw, 6rem);
            max-width: 50%;
            max-height: 50%;
        }

        @include media-breakpoint-up (xl) {
            font-size: clamp(2rem, 20vw, 9rem);
        }

        >span {
            background-color: $c-white;
        }

        // @include media-breakpoint-up (xxxl) {
        //     font-size: clamp(2rem, 20vw, 12rem); 
        // }
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__curtain {
        position: absolute;
        z-index: 2;
        background-color: $c-light;
        transition: none;
        will-change: transform;
        bottom: 0;
        left: 50%;
        width: 50%;
        height: 0;
        transform: translateY(100%);
        display: none;

        @include media-breakpoint-up (lg) {
            display: block;
        }
    }

    &__img {
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 60vh;

        @include media-breakpoint-up (lg) {
            height: 100%;
        }

        // img {
        //     user-select: none;

        //     @include media-breakpoint-up (lg) {
        //         height: 100%;
        //         width: auto;
        //     }
        // }
    }
}