body {
    position: relative;
    overflow-y: scroll;

    &.default {
        #privacy {
            .cc-revoke {
                color: $c-white !important;
                background-color: transparent !important;
            }
        }
    }
}

main {
    padding: 7rem 0 4rem 0;

    @include media-breakpoint-up (md) {
        padding: 10rem 0 3rem 0;
    }

    @include media-breakpoint-up (lg) {
        padding: 11.6rem 0 8rem 0;
    }

    &.bg-highlight {
        background-color: $c-highlight;

        .sidebar * {
            color: $c-white;
        }

        +.footer {
            .legal__link {
                color: $c-white;
            }
        }


    }

}


.container-fluid {
    max-width: 1440px;
}

// body.home {

// }

#header {
    .container-fluid {
        max-width: none;
    }
}