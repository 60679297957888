// Breakpoint detection for javascript using bootstrap 4
#device-size-detector {
    position: absolute;
    z-index: -1;
    visibility: hidden;

    >div {
        width: 1px;
        height: 1px;

        &#xs {
            background-color: red;
        }

        &#sm {
            background-color: orange;
        }

        &#md {
            background-color: yellow;
        }

        &#lg {
            background-color: greenyellow;
        }

        &#xl {
            background-color: cornflowerblue;
        }

        &#xxl {
            background-color: blue;
        }

        &#xxxl {
            background-color: blueviolet;
        }
    }
}


// Container overrides
.container {

    &-md {
        @include media-breakpoint-down (md) {
            max-width: none;
            margin: 0;
            padding: 0 2.2rem;
        }
    }

    &-lg {
        @include media-breakpoint-down (lg) {
            max-width: none;
            margin: 0;
            padding: 0 2.2rem;
        }
    }

    &-xl-up {
        @include media-breakpoint-up (xl) {
            max-width: none;
            margin: 0;
            padding: 0 2.2rem;
        }
    }

    &-off {
        &-lg {

            @include media-breakpoint-down (lg) {
                max-width: none;
                // margin: 0;
                // padding: 0 2.2rem;
            }
        }
    }
}


// Background colors
// -----------------

.bg {
    &--light {
        background-color: $c-light !important;
    }
}