html {
    font-size: 10px;

    @include media-breakpoint-up (xxxxl) {
        font-size: 12px;
    }
}

html,
body {
    font-family: $ff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c-dark;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    color: $c-dark;
}

h1 {
    font-size: $font-size-h2;
    font-weight: $fw-light;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    line-height: 1.06;
    margin-bottom: $font-size-h2;

    @include media-breakpoint-up (xl) {
        font-size: $font-size-h1;
        margin-bottom: $font-size-h1;
    }
}

h2 {
    font-size: $fs-large;
    font-weight: $fw-regular;
    // letter-spacing: 1.3px;
    line-height: 1.2;
    position: relative;
    // text-transform: uppercase;
    margin-bottom: $font-size-h3;

    @include media-breakpoint-up (xl) {
        font-size: $fs-x-large;
        margin-bottom: $fs-x-large;
    }
}

h3 {
    font-size: $fs-base;
    letter-spacing: 0.6px;
    margin-bottom: $font-size-h4;
    font-weight: $fw-bold;

    @include media-breakpoint-up (xl) {
        font-size: $fs-large;
        margin-bottom: $fs-large;
    }
}

h4 {
    font-size: $fs-large;
    // text-transform: uppercase;
    font-weight: $fw-bold;
    letter-spacing: 0.25px;
    line-height: 1.25;
    margin-bottom: $font-size-h5;

    @include media-breakpoint-up (xl) {
        margin-bottom: $font-size-h4 * 1.25;
    }
}

p {
    font-weight: $fw-regular;
    letter-spacing: 0.4px;
    line-height: 1.6;
    margin-bottom: $fs-base;
    font-size: $fs-small;
    
    @include media-breakpoint-up (md) {
        font-size: $fs-base;        
    }
}

strong {
    color: $c-dark;
}

ul,
ol {
    margin-bottom: $fs-large;

    li {
        font-size: $fs-base;
        font-weight: $fw-regular;
        letter-spacing: 0.4px;
        line-height: 1.6;
        margin-bottom: 0.5rem;
    }
}

ol {
    padding-left: 3.5rem;
}

ul {
    list-style: none;
    padding-left: 2.3rem;

    li {
        position: relative;

        &:before {
            content: "-";
            position: absolute;
            left: -1.5rem;
            font-size: inherit;
            font-weight: $fw-bold;
        }
    }
}


// Text wrapper 

.text {
    h1 {
        margin-top: $font-size-h1;
        margin-bottom: $font-size-h1/2;
    }

    h2 {
        margin-top: $font-size-h2;
        margin-bottom: $font-size-h2/2;
    }

    h3 {
        margin-top: $font-size-h3;
        margin-bottom: $font-size-h3/2;
    }

    h4 {
        margin-top: $font-size-h4;
        margin-bottom: $font-size-h4/2;
    }

    p {
        margin-top: $fs-base;
        margin-bottom: $fs-base/2;
    }

    >* {
        &:first-child {
            margin-top: 0;
        }
    }
}


// Modifiers    

.typo {

    // Sizes
    &--sm {
        font-size: $fs-small;
        line-height: 1.64;
        letter-spacing: 0.38px;
        font-weight: $fw-regular;
    }

    &--lg {
        font-size: $fs-large;
    }

    &--xl {
        font-size: $fs-x-large;
        letter-spacing: 0.6px;
        line-height: 1.45;
    }

    // Columns
    &__2col {

        @include media-breakpoint-up (xl) {
            column-count: 2;
            column-gap: 4.4rem;
        }
    }

}

// * {
//     transition: $t-short $ease-default;
// }


// Links

a {
    transition: $t-x-short $ease-default;
    display: inline-block;
    position: relative;
    text-decoration: underline;
    color: $c-highlight;
    font-size: $fs-base;
}

// .link {
//     text-decoration: none;
//     padding: 0.25rem 0;
//     position: relative;

//     &:before,
//     &:after {
//         content: "";
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         height: 2px;
//     }

//     &:before {
//         width: 100%;
//         background-color: $c-light;
//     }

//     &:after {
//         width: 0;
//         background-color: $c-highlight;
//         will-change: width;
//         transition: $t-short $ease-default;
//     }

//     &:hover {
//         text-decoration: none;

//         &:after {
//             width: 100%;
//         }
//     }

//     +.link {
//         margin-top: 1rem;
//     }

//     &__icon {
//         position: absolute;
//         left: -3.5rem;
//         top: 50%;
//         transform: translateY(-50%);
//         width: 2.2rem;
//         height: auto;
//     }

//     &--icon {
//         position: relative;
//         margin-left: 3.5rem;
//     }

//     &--back {
//         letter-spacing: 1.5px;
//         font-weight: $fw-bold;
//         text-transform: uppercase;
//         position: relative;
//         padding-left: 3rem;
//         margin-left: 2rem;

//         &:before {
//             content: "";
//             position: absolute;
//             top: 50%;
//             left: 0;
//             width: 2rem;
//             height: 2px;
//             background-color: $c-highlight;
//             will-change: width;
//             transition: $t-short $ease-default;
//         }

//         &:after {
//             display: none;
//         }

//         &:hover {
//             &:before {
//                 width: 3rem;
//                 left: -2rem;
//             }
//         }
//     }


// }



.link {
    border: none;
    text-decoration: none;
    padding: 1rem 0;
    position: relative;
    position: relative;
    display: inline-block;
    color: $c-highlight;
    font-size: $fs-small;
    hyphens: auto;
    
    @include media-breakpoint-up (lg) {
        letter-spacing: 0.6px;
        text-transform: uppercase;
        font-size: $fs-base;
    }

    &:hover {
        text-decoration: none;
        color: $c-highlight;
    }

    &--underline {
        padding: 0;
        margin: 0.5rem 0;

        &:hover {
            text-decoration: none;
            color: $c-dark;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 0.2rem;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            background-color: $c-highlight;
            transform-origin: center;
        }

        &:before {
            width: 100%;
            opacity: 0.2;
        }

        &:after {
            width: 0;
            opacity: 1;
            will-change: width;
            transition: $t-medium $ease-default;
        }

        &:hover {
            color: $c-white;

            @include media-breakpoint-up (lg) {
                color: $c-highlight;
            }

            &:after {
                width: 100%;
            }
        }

        &--active {
            &:after {
                width: 100%;
            }
        }
    }

    &--left {
        padding: 1rem 0 1rem 5rem;

        @include media-breakpoint-up (lg) {
            padding: 1rem 0 1rem 6rem;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 0.2rem;
            background-color: $c-highlight;
            top: 50%;
            right: calc(100% - 4rem);
            transform: translate(0, -50%);
            transition: $t-medium $ease-default;
            will-change: width;
        }

        &:before {
            width: 2rem;
            opacity: 0.8;
        }

        &:after {
            width: 0;
            opacity: 0.8;
        }

        &.active {
            background-color: transparent;
            color: $c-highlight;

            &:before {
                width: 4rem;
            }

            &:after {
                width: 4rem;
            }
        }

        &:hover {
            background-color: transparent;

            &:before {
                width: 4rem;
            }
        }
    }


    // Icon Links

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        width: 1.8rem;
        left: -3.5rem;

        @include media-breakpoint-up (lg) {
            left: -3.5rem;
            width: 2.2rem;
        }
    }

    &--icon {
        position: relative;
        margin-left: 3.5rem;
    }


}


// Underline styles

.underline {
    &--h1 {
        margin-bottom: $font-size-h1/2;
    }

    &--h2 {
        margin-bottom: $font-size-h2/2;
    }

    >* {
        display: inline-block;
        width: calc(100%);
        background-repeat: no-repeat;
        background-size: 0% 100%;
        transition: background-size 1s;
        background-size: 100% 90%;
        background-image: linear-gradient(transparent calc(100% - 13px), $c-highlight 13px);

        @include media-breakpoint-up (xl) {
            background-image: linear-gradient(transparent calc(100% - 18px), $c-highlight 18px);
        }
    }
}