.images {
    $images: &;

    &__link {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        ;
    }

    // Gallery

    &--gallery {
        column-count: 3;
        column-gap: 3rem;
        // display: flex;
        // flex-wrap: wrap;
        // width: calc(100% + 4rem);
        // margin-left: -2rem;

        .images__item {
            margin-bottom: 3rem;
            // width: calc(100% - 3rem);
            // margin: 1.5rem;

            // @include media-breakpoint-up(xl) {
            //     width: calc(50% - 3rem);
            // }

            // @include media-breakpoint-up(xxl) {
            //     width: calc(33% - 3rem);
            // }

            &:hover {
                img {
                    // box-shadow: 3px 3px 0 $c-highlight;
                }
            }

            a {
                &:focus {
                    outline: none;
                }
            }

            img {
                // box-shadow: $shadow-large;
                border-radius: 1px;
                overflow: hidden;
                transition: $t-short $ease-default;
                will-change: box-shadow, transform;

                &:hover {
                    box-shadow: $shadow-medium;
                    transform: translateY(1px);

                    &:active {
                        transform: translateY(2px);
                    }
                }

            }


            &:nth-child(n+7) {
                display: none;
            }
        }

    }

    &__gallery-toggle {
        &.show-all {
            #{ $images }__item {
                &:nth-child(n+7) {
                    display: block;
                }
            }

            #{ $images }__more {
                .less {
                    display: inline-block;
                }

                .more {
                    display: none;
                }
            }
        }
    }

    // Show more/less button
    &__more {
        display: block;

        .less {
            display: none;
        }
    }


    // Slider

    &--slider {
        position: relative;
        padding-top: 7rem;
        width: 100vw;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);

        @include media-breakpoint-up (xl) {
            margin: auto;
            width: 100%;
        }

        @include media-breakpoint-up (xxl) {
            padding-top: 9rem;
        }

        .swiper-slide {
            width: 65%;

            figcaption {
                transition: $t-short $ease-default;
                opacity: 0;
            }

            &-active {
                figcaption {
                    opacity: 1;
                }
            }
        }

        #{ $images }__item {
            width: 100%;
            margin: 0;

            a {
                display: block;
            }
        }

        #{ $images }__nav {
            position: absolute;
            z-index: 99;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: row;
            align-items: center;

            .swiper-pagination {
                position: relative;
            }

            .slider-btn {
                position: absolute;
            }

            &--blue {
                .slider-btn {
                    color: $c-highlight;
                }

                .swiper-pagination-bullet {
                    background-color: $c-highlight;
                }
            }
        }
    }

}

figure.interactive {
    img {
        box-shadow: $shadow-large;
    }
}

figcaption {
    font-size: $fs-small;
    color: $c-gray;
    margin-top: 1rem;
    line-height: 1.5;
}


.content--white {
    .images__item {
        img {
            // box-shadow: 5px 5px 0 $c-highlight;
        }
    }

    figcaption {
        color: $c-white;
        opacity: 0.65;
    }

    .slider-btn {

        &:before,
        &:after {
            border-color: $c-white;
        }

        .icon {
            &:before {
                color: $c-white;
            }
        }
    }

    .swiper-pagination-bullet {
        background-color: $c-white !important;
    }
}

.accordion--default {
    .images--slider {
        width: calc(100% + 8rem);
        margin-left: -4rem;
        overflow: hidden;
    }
}