.location {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    &:last-child {
        margin-bottom: 0;
    }
    
    // @include media-breakpoint-up (xxxl) {
    //     flex-direction: row;        
    // }

    &__hl {
        flex-basis: 50%;
        margin-right: 10%;
        text-transform: uppercase;
        font-size: $fs-base;
        font-weight: $fw-regular;
    }
    &__lbl {
        font-size: $fs-base;
        flex-basis: 40%;
    }
}