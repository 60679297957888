.banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up (lg) {
        flex-direction: row;
    }

    &__img,
    &__hl {
        flex-basis: 50%;
        
        @include media-breakpoint-up (lg) {
            width: 66%;            
        }
    }

    &__img {
        margin-bottom: 2rem;

        @include media-breakpoint-up (lg) {
            padding-right: 2rem;
            margin-bottom: 0;
        }
    }

    &__hl {
        color: $c-highlight;
        font-size: $fs-x-large;
        font-weight: $fw-regular;
        letter-spacing: 1px;
        margin-top: 3rem;
        // hyphens: auto;
        word-break: break-word;
    }
}