.video {
    position: relative;
    box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.15);
    margin: 3rem auto;

    @include media-breakpoint-up (xl) {
        margin: 6rem auto;
    }

    &:hover {
        cursor: pointer;
    }

    &__poster {
        width: 100%;
        position: relative;
        z-index: 3;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-bottom: 56.25%;

        iframe {
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        will-change: transform;

        &:hover {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__fallback {
        display: none;
        position: relative;
        padding: 2rem 3rem;
        border: 1rem solid $c-highlight;

        @include media-breakpoint-up (lg) {
            padding: 3rem 4rem;
        }

        * {
            color: $c-dark;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.privacy--blocked {
    .video__fallback {
        display: block;
    }

    .video__poster,
    .video__frame {
        display: none;
    }
}