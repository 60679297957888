.label {
    display: inline-block;
    font-size: $fs-small;
    text-transform: uppercase;
    font-weight: $fw-bold;
    letter-spacing: 1px;
    color: $c-highlight;
    hyphens: auto;

    &--sm {
        font-size: $fs-tiny;
    }
}