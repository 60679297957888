// @font-face {
//   font-family: 'Mark';
//   src: url('../fonts/mark/Mark-Light.eot');
//   src: url('../fonts/mark/Mark-Light.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/mark/Mark-Light.woff2') format('woff2'),
//       url('../fonts/mark/Mark-Light.woff') format('woff'),
//       url('../fonts/mark/Mark-Light.ttf') format('truetype'),
//       url('../fonts/mark/Mark-Light.svg#Mark-Bold') format('svg');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Mark';
//   src: url('../fonts/mark/Mark-Bold.eot');
//   src: url('../fonts/mark/Mark-Bold.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/mark/Mark-Bold.woff2') format('woff2'),
//       url('../fonts/mark/Mark-Bold.woff') format('woff'),
//       url('../fonts/mark/Mark-Bold.ttf') format('truetype'),
//       url('../fonts/mark/Mark-Bold.svg#Mark-Bold') format('svg');
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Mark';
//   src: url('../fonts/mark/Mark-Regular.eot');
//   src: url('../fonts/mark/Mark-Regular.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/mark/Mark-Regular.woff2') format('woff2'),
//       url('../fonts/mark/Mark-Regular.woff') format('woff'),
//       url('../fonts/mark/Mark-Regular.ttf') format('truetype'),
//       url('../fonts/mark/Mark-Regular.svg#Mark-Regular') format('svg');
//   font-weight: 400;
//   font-style: normal;
// }


@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=9929ac7d-27ba-4c4f-802e-465fa5a7de56&fontids=6215362,6460613,7151805");

@font-face {
  font-family: "Mark";
  src: url("../fonts/mark/6215362/be19d19f-d253-4979-a6d6-fd5c51d10387.woff2") format("woff2"), url("../fonts/mark/6215362/e478d528-f58a-473a-bf84-d8b21b7f7107.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Mark";
  src: url("../fonts/mark/6460613/da4f22ef-a1b6-401a-8a40-56954ce5141d.woff2") format("woff2"), url("../fonts/mark/6460613/c061ab4d-74ab-4c1c-9399-152de03f973d.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Mark";
  src: url("../fonts/mark/7151805/10b5935e-0a65-4a50-b994-4c770a3e6ab1.woff2") format("woff2"), url("../fonts/mark/7151805/d154b4cc-8431-4ad7-b2bf-f46a84f1fbe7.woff") format("woff");
  font-weight: 300;
}