.footer {
    // background-color: $c-white;

    .location {
        @extend .fullpage-slider__hl;
        position: fixed;
        z-index: -1;
        left: 1.5rem;
        padding-left: 0 !important;
        white-space: nowrap;
        bottom: 12rem;

        @include media-breakpoint-up (md) {
            bottom: 12rem;
        }
    }

}

body.home {
    .footer {
        .location {
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;

            @include media-breakpoint-up (lg) {
                top: auto;
                transform: none;
                bottom: 12rem;
            }
        }
    }
}


// Legal links

.legal {
    position: absolute;
    left: 2rem;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    margin-top: 3rem;

    @include media-breakpoint-up (lg) {
        margin-top: 0;
        left: 2rem;
        text-align: left;
        position: fixed;
    }

    &__link {
        display: inline-block;
        text-decoration: none;
        text-align: left;
        font-size: $fs-tiny;
        // background-color: $c-white;

        @include media-breakpoint-up (lg) {
            font-size: $fs-small;
        }
    }
}

body.home {
    .legal {
        position: relative;
        left: 0;

        @include media-breakpoint-up (lg) {
            position: fixed;
            left: 2rem;
        }
    }
}