.form {
    border-radius: 2px;
    position: relative;

    >*:last-child {
        margin-bottom: 0;
    }

    &--blue {
        padding: 6rem;
        background-color: $c-highlight;

        * {
            color: $c-white;
        }
    }

    &-group {
        label {
            font-size: $fs-base;
        }
    }

    label {
        font-size: $fs-base;
    }

}


.form-group {
    label {
        color: $c-highlight;
    }
}


input,
textarea {
    &.form-control {
        padding: 0.5rem 1rem;
        background-color: $c-light;
        border: none;
        height: auto;
        border-radius: 0;
        font-size: $fs-base;
        border: 2px solid $c-light;

        &:active {
            background-color: $c-white;
            border-color: $c-highlight;
        }
    }
}




.uniform__potty {
    position: absolute;
    left: -9999px;
}

// Form validation

#mc_embed_signup {
    form {
        div.mce_inline_error {
            font-size: $fs-base;
            font-weight: $fw-regular;
            margin-top: -1px;
            padding: 0.5rem 0;
            background-color: transparent;
            color: $c-red;
        }
    }
}

.invalid-feedback {
    font-size: $fs-base;
    font-weight: $fw-regular;
    background-color: transparent;
    margin-top: -1px;
    color: $c-red;
    padding: 0.5rem 0;
}


// Custom checkboxes
// --------------------

.custom-control {
    padding: 0;

    label.custom-control-label {
        font-size: $fs-small;
        font-weight: $fw-regular;
        padding-left: 3rem;

        &:hover {
            cursor: pointer;

            &:before {
                transform: scale(1.1);
                box-shadow: none;
            }
        }

        &:active,
        &:focus {

            &:before {
                border: none;
            }
        }

        &:focus {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 2px;
            outline: none;
            box-shadow: none;
        }

        &:before {
            width: 2rem;
            height: 2rem;
            border-radius: 2px;
            background-color: $c-light;
            border: none;
            position: absolute;
            left: 0;
            top: 0;
            border: 2px solid $c-light;
            @include transition($t-short $ease-default);
        }

        &:after {
            color: $c-light;
            top: 1rem;
            transform: translateY(-50%);
            left: 0;
            width: 2rem;
            height: 2rem;
        }
    }

    .custom-control-input:checked~.custom-control-label::before {
        background: $c-highlight;
    }
}