.home {

    .intro {
        // background-color: $c-highlight;
        padding-bottom: 2.2rem;
        position: relative;

        &.reveal {
            visibility: hidden;
        }
    }
}


.reveal {
    position: relative;

    &__bg {
        position: absolute;
        z-index: -1;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $c-highlight;
    }
}



.rellax--triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
}



.fullpage {
    &__hl {
        font-size: 120px;
        color: $c-light;
        text-transform: uppercase;
        font-weight: $fw-light;
        line-height: 1;
        hyphens: auto;
        max-width: 50%;
    }

    &__img {
        width: calc(100% + 3rem);        
    }

    figure {
        margin: 0;
    }
}
