body {

  &.privacy--allowed,
  &.privacy--blocked {
    #privacy {
      &:before {
        display: none;
      }
    }
  }

  &.privacy--allowed {
    //border-top: 3px solid highlightyellow;
  }

  &.privacy--blocked {

    //border-top: 3px solid red;
    #privacy {
      background-color: transparent;
    }
  }

  #privacy {

    .cc-window {
      z-index: 9999999;
      max-width: 60rem;
      background-color: $c-highlight !important;
      padding: 2rem;
      border-radius: 2px;
      left: 50%;
      width: 90%;
      box-shadow: $shadow-large;

      @include media-breakpoint-up (md) {
        padding: 4rem;
      }

      @include media-breakpoint-up (xl) {
        padding: 6rem;
      }
    }

    .cc-compliance {
      display: flex;
      flex-direction: row;

      >a {
        text-decoration: none;

        &:before {
          display: none;
        }
      }
    }

    .cc-btn {
      font-size: $fs-base;
      padding: 1rem;
      border-radius: 2px;
      border-width: 2px;
      @include transition($t-short $ease-default);

      &:hover,
      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:hover {
        text-decoration: none;
      }

      .cc-allow {
        background-color: $c-white !important;
      }
    }

    .cc-allow {
      flex-basis: 65%;
      color: $c-highlight !important;
      background-color: $c-white !important;

      a {
        background-color: $c-white !important;
      }

      &:hover,
      &:focus {
        // transform: scale(1.03);
        text-decoration: none;
      }
    }

    .cc-deny {
      flex-basis: 35%;
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.4);
      margin-left: 1rem;
      opacity: 0.8;

      &:hover {
        border-color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
      }
    }

    .cc-message {
      font-size: $fs-small;
      line-height: $line-height-base;
      color: $c-white;
      margin: 0 0 3rem 0;
      padding: 0;

      a {
        font-size: $fs-small;
      }
    }

    .cc-revoke {
      font-family: $font-family-base;
      color: $c-highlight;
      background-color: $c-white !important;
      z-index: 999;
      left: 2rem;
      right: auto;
      bottom: 2.7rem;
      padding: 0 !important;
      margin: 0;
      font-weight: $fw-regular;
      border-top-left-radius: 2px;
      transition: .3s ease-out;
      font-size: $fs-tiny !important;
      display: inline-block !important;
      width: auto;
      position: absolute;
      
      @include media-breakpoint-up (xl) {
        font-size: $fs-small !important;
        position: fixed;
      }

      &.visible {
        transform: translateY(0);
      }
    }
  }
}


.cc-theme-edgeless.cc-window {
  background-color: $c-dark !important;
  @include box-shadow(0 32px 48px -12px rgba(0, 0, 0, 0.5));
}

.cc-floating.cc-theme-edgeless .cc-message {
  color: $c-white;
  font-size: $fs-small;

  a {
    color: $c-white;
    font-size: $fs-small;
  }

  button {
    color: $c-white;
  }
}

.cc-compliance {

  .cc-highlight .cc-btn {
    color: $c-white;

  }
}

.cc-window {
  &.cc-center {
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    transform: translate(-50%, -50%);
    // display: none;
  }
}


// Always show revoke button
#privacy {
  .cc-revoke {
    display: block !important;
  }
}


.cc-highlight .cc-btn:first-child {
  background-color: $c-highlight !important;
  color: $c-dark !important;

  &:hover {
    text-decoration: none !important;
  }

}


.cc-revoke {
  background-color: $c-highlight !important;
  // color: $c-white !important;
  padding: 0.25rem 0.5rem !important;
  right: 0;
  bottom: 0;
  text-decoration: none;
  font-size: $fs-small;
  user-select: none;
  display: none !important;
  font-weight: $fw-regular;
  transition: 0.3s ease-out;

  &:hover {
    background-color: $c-highlight !important;
    text-decoration: none !important;
    color: $c-dark !important;
  }
}

body.default {
  .cc-revoke {
    display: block !important;
  }
}

body.data-privacy {
  .cc-revoke {
    display: block !important;
  }
}