.card {
    border: none;
    border-radius: 0;
    margin-bottom: 3rem;

    &:last-child {
        margin-bottom: 0;
    }

    &+.card {
        border-top: 2px solid $c-light;
        margin-top: 2rem;
        padding-top: 2rem;
    }

    &--horizontal {
        flex-direction: column;

        @include media-breakpoint-up (lg) {
            flex-direction: row;
        }
    }

    &--contact {
        width: auto;

        .card-img {
            flex-basis: auto;
            max-width: 24rem;
        }
    }





    &-img {
        height: 100%;

        @include media-breakpoint-up (lg) {
            margin-right: 2rem;
        }
    }

    figure {
        width: 100%;
        display: contents;
    }

    &-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }


    // Types

    &--project,
    &--referenz {
        .card-img {
            margin-bottom: 2rem;

            @include media-breakpoint-up (lg) {
                margin-bottom: 0;
                flex-basis: 35%;
                max-width: 50%;
            }

            @include media-breakpoint-up (xl) {
                max-width: 35%;
            }
        }

        .card-body {
            @include media-breakpoint-up (lg) {
                flex-basis: 65%;
            }
        }
    }

    &--referenz {
        .card-img {
            flex-basis: 35%;
            // max-width: 35%;
            
            @include media-breakpoint-up (lg) {
                max-width: 35%;                
            }
        }

        .card-body {

            flex-basis: 65%;
        }
    }

    &--contact {
        .card-img {
            margin-bottom: 2rem;

            @include media-breakpoint-up (lg) {
                margin-bottom: 0;
            }
        }

        .card-lbl {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }


    &--contact {
        figure {
            display: inline-block;
        }
    }


}