.infobox {
    border: 2px solid $c-highlight;
    padding: 4rem;

    &__hl {
        background-color: $c-light;
        padding: 2rem 4rem;
        margin: -4rem 0 4rem -4rem;
        width: calc(100% + 8rem);
    }

    &__copy {
        >*:last-child {
            margin-bottom: 0;
        }
    }
}