.logo {
    padding: 1rem 0;
    z-index: 99;
    transition: none;
    // display: none;

    &:before {
        display: none;
    }

    img {
        width: 12rem;
        user-select: none;
        will-change: width;
        
        @include media-breakpoint-up (md) {
            padding: 0.8rem 1.2rem;
            width: 24rem;
            max-width: 24rem;
        }
        
        @include media-breakpoint-up (lg) {
            width: 32rem;
            padding: 0.8rem 1.2rem;
            max-width: 32rem;
        }
    }
}