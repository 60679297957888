.btn {
    color: $c-highlight;
    text-transform: uppercase;
    font-weight: $fw-regular;
    background-color: $c-light;
    display: inline-block;
    position: relative;
    padding: 1.5rem 2rem;
    text-decoration: none;
    font-size: $fs-small;
    letter-spacing: 1px;
    text-align: left;
    border: none;
    border-radius: 0;

    @include media-breakpoint-up (xl) {
        letter-spacing: 1px;
        font-size: $fs-base;
        padding: 1.5rem 2rem;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $c-white;
    }

    &:before {
        display: none;
    }

    &--icon {
        transition: $t-short $ease-default;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:hover {
            transform: translateX(1rem);
            padding-right: 4rem;

            .btn__icon {
                padding-left: 3rem;
            }

            .icon-arrow {
                width: 4.5rem;
            }
        }
    }

    &__icon {
        position: relative;
        line-height: 1;
        padding-left: 2rem;
    }

    &__text {
        white-space: nowrap;
    }


    // Arrow icon

    .icon {
        display: inline-block;

        &-arrow {
            position: relative;
            width: 3.1rem;
            height: 1.5rem;

            &__shaft {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                width: 100%;
                height: 2px;
                background-color: $c-white;
                border-top-right-radius: 1px;
                border-bottom-right-radius: 1px;
                transition: $t-short $ease-default;
            }

            &__head {
                position: absolute;
                top: -1px;
                right: 0.25px;
                transform: translateY(calc(50% - 0px));
                width: 1rem;
                height: 1.5rem;
                display: inline-block;

                &:before,
                &:after {
                    content: " ";
                    display: block;
                    width: 1rem;
                    height: 1.75px;
                    background-color: $c-white;
                    position: absolute;
                    transform-origin: right;
                }

                &:before {
                    transform: translateY(0.5px) rotate(45deg);
                }

                &:after {
                    transform: translateY(-0.5px) rotate(-45deg);
                }
            }
        }
    }


    // CTA

    &--cta {
        &:hover {
            transform: translateX(1rem);
        }
    }

    // Video button

    &--video {
        background-color: $c-highlight;

        .btn__text {
            color: $c-dark;
        }

        .btn__icon {
            .icon-arrow__shaft {
                background-color: $c-dark;
            }

            .icon-arrow__head {

                &:before,
                &:after {
                    background-color: $c-dark;
                }
            }
        }
    }
}