.builder-element {
    position: relative;

    + .builder-element:not(.copy) {
        margin-top: 8rem;

        &:before {
            content: "";
            position: absolute;
            top: -4rem;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $c-light;
        }
    }
}