.nav {
    height: 0;

    @include media-breakpoint-up (lg) {
        position: absolute;
        bottom: 1px;
        right: 4rem;
        height: auto;
    }

    &.active {
        display: block;

        .nav__menu {
            display: block;
        }
    }

    &--container {
        @include media-breakpoint-only (lg) {
            max-width: none;
        }
    }


    // Menu

    &__menu {
        flex-direction: column;
        z-index: 99;
        position: absolute;
        left: 0;
        width: 100%;
        margin-top: 2rem;
        display: none;

        @include media-breakpoint-up (lg) {
            margin-top: 0;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            left: inherit;
        }

        @include media-breakpoint-up (xl) {
            margin-right: -2rem;
        }
    }

    // Menu links

    &__link {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-stretch: normal;
        display: block;
        width: 100%;
        // border-top: 1px solid $c-gray;
        position: relative;
        text-decoration: none;
        padding-bottom: 3rem;
        transition: none;
        font-size: $fs-x-large;
        font-weight: $fw-light;
        
        @include media-breakpoint-up (lg) {
            font-weight: $fw-regular;
            padding-bottom: 1rem;
            font-size: $fs-large;
            margin: 1rem 1rem 0 1rem;
            display: inline-block;
            width: auto;
            border: none;
        }

        @include media-breakpoint-up (xl) {
            margin: 1rem 2rem 0 2rem;
        }

        &:last-child {
            // border-bottom: 1px solid $c-gray;

            @include media-breakpoint-up (lg) {
                border: none;
            }
        }

        &:before,
        &:after {
            display: none;
            content: "";
            position: absolute;
            height: 0.2rem;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            background-color: $c-highlight;
            transform-origin: center;

            @include media-breakpoint-up (lg) {
                display: block;
            }
        }

        &:before {
            width: 100%;
            opacity: 0.2;
        }

        &:after {
            width: 0;
            opacity: 1;
            will-change: width;
            transition: $t-medium $ease-default;
        }

        &:hover {
            text-decoration: none;

            @include media-breakpoint-up (lg) {
                color: $c-highlight;
            }

            &:after {
                width: 100%;
            }
        }

        &:focus {
            outline: none;
        }

        &--active {
            &:after {
                width: 100%;
            }
        }
    }


    // Burger button

    &__burger {
        visibility: hidden;
        display: block;
        width: 3rem;
        height: 3rem;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-up (lg) {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        line {
            stroke-width: 2px;
            transition: none;
        }

        #burger line {
            stroke: $c-highlight;
        }

        #close line {
            stroke: $c-highlight;
        }
    }


    // Mobile nav curtain

    &__curtain {
        position: fixed;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $c-white;
        display: none;
        // opacity: 0;
    }


    // Mobile nav title

    // &__title {
    //     font-size: $fs-xl-large;
    //     font-weight: $fw-bold;
    //     font-stretch: condensed;
    //     color: $c-highlight;
    //     position: absolute;
    //     z-index: 99;
    //     top: 50%;
    //     left: 0;
    //     transform: translateY(-50%);
    //     display: block;
    //     opacity: 0;

    //     @include media-breakpoint-up (lg) {
    //         display: none;
    //     }
    // }
}