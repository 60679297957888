.job {

    &__item {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        // border-top: 2px solid $c-light-gray;
        // border-bottom: 2px solid $c-light-gray;

        +.job__item {
            margin-top: -2px;
        }
    }

    &__header {
        padding: 0;
        border: 0;
        background-color: transparent;
    }

    &__btn {
        font-weight: $fw-bold;
        border: 0;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: left;
        width: 100%;
        padding: 2rem 0;
        margin: 0;
        text-align: left;
        background-color: transparent;
        align-items: center;
        text-transform: inherit;
        font-size: $fs-small;
        line-height: 1.4;

        @include media-breakpoint-up (lg) {
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font-size: $fs-large;
        }

        &:hover,
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.collapsed {
            .icon {
                transform: scale(0.8) rotate(90deg);

                @include media-breakpoint-up (lg) {
                    transform: scale(1) rotate(90deg);
                }
            }
        }

        .text {
            display: block;
        }

        .icon {
            position: relative;
            width: 4rem;
            height: 4rem;
            transition: $t-medium $ease-default;
            transform: scale(0.8) rotate(-90deg);

            @include media-breakpoint-up (lg) {
                transform: scale(1) rotate(-90deg);
            }

            span.top,
            span.bottom {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2rem;
                height: 0.2rem;
                background-color: $c-dark;
                transform-origin: right;
            }

            span.top {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            span.bottom {
                transform: translate(-50%, -50%) rotate(-45deg);

            }

            &-prev {
                left: 0;
                margin-left: calc((-100vw + 100%)/2);

                span.top,
                span.bottom {
                    transform-origin: left;
                }
            }
        }
    }


    &__content {
        padding: 2rem 12rem 4rem 0;
        margin: 0;

        .text {
            display: block;

            * {
                font-size: $fs-tiny;

                @include media-breakpoint-up (lg) {
                    font-size: $fs-base;
                }
            }
        }
    }
}