.infos {

    &__hl,
    &__text {
        line-height: 1.5;
    }
    
    &__text {
        letter-spacing: normal;
    }
}