.header {
    // position: relative;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    background-color: $c-white;
    box-shadow: $shadow-medium;
    
    @include media-breakpoint-up (lg) {
        overflow: hidden;
        box-shadow: $shadow-large;        
    }

    &.active {
        overflow: visible;
    }

    &__inner {
        position: relative;
        padding: 1rem 0;

        @include media-breakpoint-up (lg) {
            padding: 0;
        }
    }

    // &__inner {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     align-items: flex-end;
    //     width: 100%;
    //     position: relative;
    //     z-index: 2;
    //     // margin-top: 1.5rem;

    //     @include media-breakpoint-up (md) {
    //         // margin-top: 3rem;        
    //         margin: 1rem auto;
    //     }
    // }

    &.minified {
        .logo > img {
            width: 12rem;
        }
    }
}

// main {
//     margin-top: 20rem;
// }