img {
    width: 100%;
    height: auto;
    // box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.15);
}

.img {
    &--default {
        width: auto;
        height: auto;
        box-shadow: none;
    }
}

// .parallax {
//     opacity: 0;
//     transition-property: opacity !important;

//     &.show {
//         opacity: 1;
//     }
// }


figure {
    // width: 100%;
}

figcaption {
    text-decoration: none;
}

a figcaption {
    text-decoration: none;
}

img[data-sizes="auto"] {
    display: block;
    width: 100%;
}


/* class name must matches the value of `ratio` setting. */
.lazysrcset-ratio {
    position: relative;
}

.lazysrcset-ratio>img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.lazysrcset-ratio>img:after {
    display: block;
    width: 100%;
    height: 0;
    content: '';
}






/*
Makes images container to occupy some space 
when the images aren't loaded yet.
This value depends on your layout.
*/
img.lazy {
    // min-height: 300px;
}

/*
Avoid empty images to appear as broken
*/
img:not([src]):not([srcset]) {
    visibility: hidden;
}

/* 
Fixes the Firefox anomaly while images are loading
*/
@-moz-document url-prefix() {
    img:-moz-loading {
        visibility: hidden;
    }
}