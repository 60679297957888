.group {
    position: relative;
    z-index: 1;
    background: $c-white;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: $shadow-medium;

    &__container {
        position: relative;

        &:first-child {
            .group {
                padding-top: 4rem;

                &__label {
                    top: 2rem;
                    margin-top: -2rem;
                    
                    @include media-breakpoint-up (md) {
                        top: 4rem;                        
                        margin-top: -4rem;
                    }
                }
                
                @include media-breakpoint-up (md) {
                    padding-top: 6rem;                    
                }
            }
        }
    }

    &__label {
        position: absolute;
        z-index: 0;
        display: block;
        right: 0;
        transform: translateX(100%) rotate(90deg) translateY(-98%);
        padding: 1.5rem 2rem;
        background-color: $c-white;
        letter-spacing: 1px;
        color: $c-highlight;
        transform-origin: left top;
        text-transform: uppercase;
        transition: none;
        font-size: $fs-small;
        top: 0;
        
        @include media-breakpoint-up (md) {
            font-size: $fs-large;            
        }
    }

    &__spy {
        position: fixed;
    }

    &--job {
        width: calc(100% + 5rem);

        @include media-breakpoint-up (md) {
            width: auto;
        }
    }
}

.scrollspy {
    position: relative;
}